import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OverviewSystem, TimeRangeGranularityEnum, TimeRangeInterface } from '@twaice-fe/shared/models';

import { TimeRangeEnum } from '@twaice-fe/shared/utilities';
import * as SystemsActions from '../actions/systems.actions';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const SYSTEMS_FEATURE_KEY = 'systems';

export interface State extends EntityState<OverviewSystem> {
  config: ListConfigurationInterface;
  selectedId?: string; // which Systems record has been selected
  isLoading: boolean; // has the Systems list been loaded
  isEfcLoading?: boolean;
  isSohLoading?: boolean;
  error?: string | null; // last known error (if any)
  timeRange?: TimeRangeInterface | null;
  availableTimeRanges?: TimeRangeInterface[] | null;
  defaultTimeRange?: TimeRangeEnum;
  timeRangeGranularity: TimeRangeGranularityEnum | null;
}

export interface SystemsPartialState {
  readonly [SYSTEMS_FEATURE_KEY]: State;
}

export const systemsAdapter: EntityAdapter<OverviewSystem> = createEntityAdapter<OverviewSystem>();
export const initialState: State = systemsAdapter.getInitialState({
  selectedId: '',
  config: {
    limit: 10,
    page: 1,
    totalPages: 1,
    columns: [],
  },
  isLoading: false,
  isEfcLoading: false,
  timeRange: null,
  availableTimeRanges: null,
  defaultTimeRange: TimeRangeEnum.LAST_THIRTY_DAYS,
  timeRangeGranularity:
    TimeRangeGranularityEnum[
      (new URL(window.location.href).searchParams.get('granularity') as keyof typeof TimeRangeGranularityEnum) || 'WEEKLY'
    ],
});

const systemsReducer = createReducer(
  initialState,
  on(SystemsActions.fetchSystems, (state) => ({ ...state, isLoading: true, error: null })),
  on(SystemsActions.fetchSystemsEfcData, (state) => ({ ...state, isEfcLoading: true, isLoading: true, error: null })),
  on(SystemsActions.fetchSystemsSohData, (state) => ({ ...state, isSohLoading: true, isLoading: true, error: null })),
  on(SystemsActions.loadSystemsSuccess, (state, { data }) =>
    Object.keys(state?.entities)?.length > 0
      ? {
          ...state,
          entities: {
            ...state.entities,
            ...Object.assign(
              {},
              ...data.items.map(({ id, ...rest }) => ({
                [id]: {
                  ...state.entities[id],
                  ...rest,
                  kpis: {
                    ...state.entities[id]?.kpis,
                    ...rest?.kpis,
                  },
                  metadata: {
                    ...state.entities[id]?.metadata,
                    ...rest?.metadata,
                  },
                  warrantyMetadata: {
                    ...state.entities[id]?.warrantyMetadata,
                    ...rest?.warrantyMetadata,
                  },
                  systemStatistics: {
                    ...state.entities[id]?.systemStatistics,
                    ...rest?.systemStatistics,
                  },
                },
              }))
            ),
          },
          config: { ...state.config, page: data.page, limit: data.pageSize, totalPages: data.totalPages },
          isLoading: state.isEfcLoading ?? false,
        }
      : systemsAdapter.upsertMany(data.items, {
          ...state,
          config: { ...state.config, page: data.page, limit: data.pageSize, totalPages: data.totalPages },
          isLoading: false,
        })
  ),
  on(
    ...[
      SystemsActions.loadSystemsEfcDataSuccess,
      SystemsActions.loadSystemsSohDataSuccess,
      SystemsActions.loadSystemsHealthKpiDataSuccess,
    ],
    (state, { data }) => ({
      ...state,
      entities: {
        ...state.entities,
        ...Object.assign(
          {},
          ...data.items.map(({ id, kpis, systemStatistics }) => ({
            [id]: {
              ...state.entities[id],
              kpis: {
                ...state.entities[id]?.kpis,
                ...kpis,
              },
              systemStatistics: {
                ...state.entities[id]?.systemStatistics,
                ...systemStatistics,
              },
            },
          }))
        ),
      },
      config: { ...state.config, page: data.page, limit: data.pageSize, totalPages: data.totalPages },
    })
  ),
  on(SystemsActions.loadSystemsHealthKpiDataSuccess, (state) => ({ ...state, isLoading: false })),
  on(SystemsActions.loadSystemsEfcDataSuccess, (state) => ({
    ...state,
    isEfcLoading: false,
    isLoading: false,
  })),
  on(SystemsActions.loadSystemsSohDataSuccess, (state) => ({
    ...state,
    isSohLoading: false,
    isLoading: false,
  })),
  on(SystemsActions.systemSohLoadingSuccess, (state) => ({
    ...state,
    isSohLoading: false,
    isLoading: false,
  })),
  on(SystemsActions.systemEfcLoadingSuccess, (state) => ({
    ...state,
    isEfcLoading: false,
    isLoading: false,
  })),
  on(SystemsActions.loadSystemsFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
  on(SystemsActions.selectSystem, (state, { systemId }) => ({
    ...state,
    selectedId: systemId,
  })),
  on(SystemsActions.systemLoadingSuccess, (state) => ({ ...state, isLoading: false })),
  on(SystemsActions.setDefaultTimeRange, (state, { defaultTimeRange }) => ({
    ...state,
    defaultTimeRange,
  })),
  on(SystemsActions.setInitialTimeRange, (state, { timeRange, availableTimeRanges }) => ({
    ...state,
    timeRange,
    availableTimeRanges,
  })),
  on(SystemsActions.setTimeRange, (state, { timeRange }) => ({
    ...state,
    timeRange,
  })),
  on(SystemsActions.setTimeRangeGranularity, (state, { granularity }) => ({
    ...state,
    timeRangeGranularity: granularity,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return systemsReducer(state, action);
}
