import { IncidentsAnalyticsInterface, SafetyState } from '../features/incidents-detection.models';
import { SystemStatisticsData } from '../features/performance-manager.models';
import { AggregationScope, AggregationType, HealthRequestParameters, KPI } from './health.models';
import { FeWarrantyMetadata } from './metadata.models';

export class System {
  id: string;
  name?: string;
  description?: string;
  customerId?: string;
  customerBk?: string;
  rootContainerId?: string;
  systemBk?: string;
}

export interface KpisInterface {
  id: string;
  containerName?: string;
  efcAvg?: number;
  efcSum: number;
  sohCMax: number;
  sohCMin: number;
}

export class OverviewSystem extends System {
  rootContainerId?: string;
  metadata?: {
    id: string;
    calculationLevel: number;
    nominalEnergyCapacity: string;
    maximumPower: string;
    batteryType: string;
    installationDate?: Date;
    commercialOperationDate?: Date;
    numString?: number;
    location?: string;
  };
  warrantyMetadata?: FeWarrantyMetadata;
  kpis?: KpisInterface;
  incidents?: IncidentsAnalyticsInterface;
  systemStatistics?: SystemStatisticsData;
  safetyState?: SafetyState;
}

export const EFCHealthKPIS: HealthRequestParameters[] = [
  {
    kpi: KPI.EQUIVALENT_FULL_CYCLES,
    aggregationType: AggregationType.AVG,
    aggregationScope: AggregationScope.ACROSS_ENTITIES,
  },
];

export const StateOfHealthKPIS = [
  {
    kpi: KPI.STATE_OF_HEALTH_CAPACITY,
    aggregationType: AggregationType.MIN,
    aggregationScope: AggregationScope.ACROSS_ENTITIES,
    useLatest: true,
  },
  {
    kpi: KPI.STATE_OF_HEALTH_CAPACITY,
    aggregationType: AggregationType.MAX,
    aggregationScope: AggregationScope.ACROSS_ENTITIES,
    useLatest: true,
  },
  {
    kpi: KPI.EQUIVALENT_FULL_CYCLES,
    aggregationType: AggregationType.AVG,
    aggregationScope: AggregationScope.ACROSS_ENTITIES,
    useLatest: true,
  },
];

export interface TimeRangeInterface {
  id: string;
  value: [Date, Date] | string;
  label: string;
  disabled?: boolean;
}

export enum TimeRangeGranularityEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}
