import {
  severityCritical,
  severityLow,
  severityMedium,
  severityNormal,
  StackedBarInfo,
} from '@twaice-fe/frontend/shared/utilities';
import { PerformanceManagerKPI, BalancingSeverityEnum } from '@twaice-fe/shared/models';

export enum TemperatureSeverityEnum {
  CRITICAL = 'critical',
  ELEVATED = 'elevated',
  NORMAL = 'optimal',
  LOW = 'low',
  MISSING = 'missing',
}

export interface KpiInfoDetails {
  label: string;
  longLabel?: string;
  unit?: string;
  visualisationThresholds: KPIVisualisationThresholds;
  precision: string;
  multiplyBy?: number;
}

export interface KpiInfoDetails {
  label: string;
  longLabel?: string;
  unit?: string;
  visualisationThresholds: KPIVisualisationThresholds;
  precision: string;
  multiplyBy?: number;
}

export interface KPIInfoInterface {
  [key: string]: KpiInfoDetails;
}

export interface KPIVisualisationThresholds {
  [BalancingSeverityEnum.CRITICAL]?: number;
  [BalancingSeverityEnum.ELEVATED]?: number;
  [BalancingSeverityEnum.NORMAL]?: number;
  [BalancingSeverityEnum.MISSING]?: number;
  [TemperatureSeverityEnum.CRITICAL]?: number;
  [TemperatureSeverityEnum.ELEVATED]?: number;
  [TemperatureSeverityEnum.NORMAL]?: number;
  [TemperatureSeverityEnum.LOW]?: number;
  [TemperatureSeverityEnum.MISSING]?: number;
}

export const kpiInfo: KPIInfoInterface = {
  [PerformanceManagerKPI.VOLTAGE_SPREAD]: {
    label: 'Voltage spread',
    unit: 'mV',
    visualisationThresholds: {
      [BalancingSeverityEnum.CRITICAL]: 200,
      [BalancingSeverityEnum.ELEVATED]: 50,
      [BalancingSeverityEnum.NORMAL]: 0,
      [BalancingSeverityEnum.MISSING]: 0,
    },
    precision: '1.0-0',
    multiplyBy: 1000,
  },
  [PerformanceManagerKPI.SOC_SPREAD]: {
    label: 'SoC spread',
    unit: '%',
    visualisationThresholds: {
      [BalancingSeverityEnum.CRITICAL]: 100,
      [BalancingSeverityEnum.ELEVATED]: 15,
      [BalancingSeverityEnum.NORMAL]: 0,
      [BalancingSeverityEnum.MISSING]: 0,
    },
    precision: '1.0-1',
  },
  [PerformanceManagerKPI.TEMPERATURE_MAX]: {
    label: 'Temperature max',
    longLabel: 'extended temperature',
    unit: '°C',
    visualisationThresholds: {
      [TemperatureSeverityEnum.CRITICAL]: 50,
      [TemperatureSeverityEnum.ELEVATED]: 33,
      [TemperatureSeverityEnum.NORMAL]: 25,
      [TemperatureSeverityEnum.LOW]: 0,
      [TemperatureSeverityEnum.MISSING]: 0,
    },
    precision: '1.0-2',
  },
  [PerformanceManagerKPI.TEMPERATURE_SPREAD]: {
    label: 'Temperature spread',
    longLabel: 'high temperature spread',
    unit: '°C',
    visualisationThresholds: {
      [TemperatureSeverityEnum.CRITICAL]: 15,
      [TemperatureSeverityEnum.ELEVATED]: 8,
      [TemperatureSeverityEnum.NORMAL]: 0,
      [TemperatureSeverityEnum.MISSING]: 0,
    },
    precision: '1.0-2',
  },
  [PerformanceManagerKPI.TEMPERATURE_MEAN]: {
    label: 'Temperature mean',
    unit: '°C',
    visualisationThresholds: {
      [TemperatureSeverityEnum.CRITICAL]: 45,
      [TemperatureSeverityEnum.ELEVATED]: 30,
      [TemperatureSeverityEnum.NORMAL]: 30,
      [TemperatureSeverityEnum.LOW]: 0,
      [TemperatureSeverityEnum.MISSING]: 0,
    },
    precision: '1.0-2',
  },
};

export const temperatureStackedBarInfo: StackedBarInfo = {
  [TemperatureSeverityEnum.CRITICAL]: {
    color: severityCritical,
    label: 'Critical module temperatures',
    thresholdText: '-',
  },
  [TemperatureSeverityEnum.ELEVATED]: {
    color: severityMedium,
    label: 'Elevated module temperatures',
    thresholdText: '-',
  },
  [TemperatureSeverityEnum.NORMAL]: {
    color: severityNormal,
    label: 'Optimal module temperatures',
    thresholdText: '-',
  },
  [TemperatureSeverityEnum.MISSING]: { color: severityLow, label: 'Missing data ' },
};
