import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import { IncidentSeverityEnum, IncidentsAnalyticsInterface } from '@twaice-fe/shared/models';
import { map, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { DataDisplayModule } from '../data-display/data-display.module';
import { keysToSnake } from '@twaice-fe/shared/utilities';

@Component({
  selector: 'twaice-fe-alerts-overview',
  templateUrl: './alerts-overview.component.html',
  styleUrls: ['./alerts-overview.component.scss'],
  standalone: true,
  imports: [CommonModule, TxNativeModule, SvgIconModule, MatCardModule, NzButtonModule, DataDisplayModule],
})
export class AlertsOverviewComponent implements OnInit {
  @Input() incidentsCount$: Observable<IncidentsAnalyticsInterface>;
  activeFilters: Record<string, string>;

  incidentSeverityEnum = IncidentSeverityEnum;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private destroy$: DestroyRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectors.incidentsDetectionSelectors.getIncidentsConfig)
      .pipe(
        map((config) => config.filter),
        takeUntilDestroyed(this.destroy$)
      )
      .subscribe((filters) => {
        this.activeFilters = keysToSnake(filters || {});
      });
  }

  setSeverityFilter(severity: IncidentSeverityEnum): void {
    const filter = {
      ...this.activeFilters,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      max_severity: this.activeFilters?.max_severity === severity ? undefined : severity,
    };

    this.store.dispatch(actions.incidentsDetectionActions.filterIncidents({ filter }));

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
  }
}
