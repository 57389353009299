import { Action, createReducer, on } from '@ngrx/store';
import { KpiDistributionInterface } from '../../../../features/energy-analytics/health/src/lib/models/kpis-distribution.models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ListConfigurationInterface } from '../types/list-configuration.interface';
import { healthAction } from '../actions';

export const HEALTH_FEATURE_KEY = 'health';
export const HEALTH_STRINGS_DISTRIBUTION_FEATURE_KEY = 'stringsDistribution';

export interface StringsDistributionState extends EntityState<KpiDistributionInterface> {
  config: ListConfigurationInterface;
  customFilters?: Record<string, any>;
  selectedIds?: string[] | number[]; // which Systems record has been selected
  isLoading: boolean; // has the Systems list been loaded
  error?: string | null; // last known error (if any)
}

export interface HealthKpisDataInterface {
  kpisChartData: { [kpis: string]: any };
  kpisChartDataInfo: { [kpis: string]: any };
  kpisChartMissingDataInfo: { [kpis: string]: any };
  kpisLineChartData: { [kpis: string]: any };
}

export interface State {
  stringsDistribution: StringsDistributionState;
  healthKpis: HealthKpisDataInterface;
}

export const stringsDistributionAdapter: EntityAdapter<KpiDistributionInterface> = createEntityAdapter<KpiDistributionInterface>({
  selectId: (model) => model.containerID,
});

export const initialState: State = {
  stringsDistribution: stringsDistributionAdapter.getInitialState({
    selectedIds: [],
    config: {
      limit: 20,
      page: 1,
      totalPages: 1,
      order: 'containerName:asc',
    },
    isLoading: false,
  }),
  healthKpis: {
    kpisChartData: {},
    kpisChartDataInfo: {},
    kpisChartMissingDataInfo: {},
    kpisLineChartData: {},
  },
};

const healthReducer = createReducer(
  initialState,
  on(healthAction.fetchHealthKpiData, (state) => ({
    ...state,
    stringsDistribution: {
      ...state.stringsDistribution,
      ...stringsDistributionAdapter.getInitialState(),
      isLoading: true,
    },
  })),
  on(healthAction.loadHealthStringsDistributionSuccess, (state, { data }) => ({
    ...state,
    stringsDistribution: stringsDistributionAdapter.upsertMany(
      data.items, {
      ...state.stringsDistribution,
      config: {
        ...state.stringsDistribution.config,
        page: data.page ?? 1,
        limit: data.pageSize ?? 1,
        totalPages: data.totalPages ?? 1,
        totalItems: data?.totalItems ?? 0,
      },
      isLoading: false,
    }),
  })),
  on(healthAction.updateStringsDistributionListConfiguration, (state, { config }) => ({
    ...state,
    stringsDistribution: {
      ...state.stringsDistribution,
      config: { ...state.stringsDistribution.config, ...(config.page ? config : { ...config, page: 1 }) },
    },
  })),
  on(healthAction.stringsDistributionColumnPicker, (state, { columns }) => ({
    ...state,
    stringsDistribution: {
      ...state.stringsDistribution,
      config: { ...state.stringsDistribution.config, columns },
    },
  })),
  on(healthAction.loadSelectedStrings, (state, { ids }) => ({
    ...state,
    stringsDistribution: {
      ...state.stringsDistribution,
      selectedIds: ids,
    },
  })),
  on(healthAction.setCustomFilter, (state, { filters }) => ({
    ...state,
    stringsDistribution: {
      ...state.stringsDistribution,
      customFilters: {
        ...filters,
      },
    },
  })),
  on(healthAction.loadHealthKpiDataSuccess, (state, { data }) => ({
    ...state,
    healthKpis: {
      ...state.healthKpis,
      kpisChartData: { ...state.healthKpis.kpisChartData, ...data?.['kpisChartData'] },
      kpisChartDataInfo: { ...state.healthKpis.kpisChartDataInfo, ...data?.['kpisChartDataInfo'] },
      kpisChartMissingDataInfo: { ...state.healthKpis.kpisChartMissingDataInfo, ...data?.['kpisChartMissingDataInfo'] },
    },
  })),
  on(healthAction.sortStringsDistribution, (state, { order }) => ({
    ...state,
    stringsDistribution: {
      ...state.stringsDistribution,
      config: {
        ...state.stringsDistribution.config,
        order,
      },
    },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return healthReducer(state, action);
}
