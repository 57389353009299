import { Component, Input } from '@angular/core';
import { MemoizedSelector, Store } from '@ngrx/store';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { actions, FeatureKeyType, ListConfigurationInterface, reducers, selectors } from '@twaice-fe/frontend/shared/store';

const { incidentsDetectionSelectors, healthSelectors, IncidentRulesSelectors, energyPredictionSelectors } = selectors;
const { incidentsDetectionActions, healthAction, IncidentRulesActions, EnergyPredictionActions } = actions;
const {
  incidentsDetectionReducer: { INCIDENTS_FEATURE_KEY },
  healthReducer: { HEALTH_STRINGS_DISTRIBUTION_FEATURE_KEY },
  incidentRulesReducer: {
    INCIDENTS_RULES_DEFINITION_FEATURE_KEY,
    INCIDENTS_RULES_THRESHOLD_FEATURE_KEY,
    INCIDENTS_RULES_DELAYS_FEATURE_KEY,
  },
  energyPredictionReducer: { ENERGY_PREDICTION_FEATURE_KEY },
} = reducers;

@Component({ template: '' })
export abstract class BaseStoreComponent {
  @Input() uniqueStateName: FeatureKeyType;

  constructor(protected store: Store) {}

  /**
   * Dynamically return selector of the unique list name
   * MemoisedSelector have the any type here because it can have multiple types which are not possible to state at this stage
   */
  getSelector(): MemoizedSelector<any, any, any> {
    switch (this.uniqueStateName) {
      case INCIDENTS_FEATURE_KEY:
        return incidentsDetectionSelectors.getIncidentsState;
      case HEALTH_STRINGS_DISTRIBUTION_FEATURE_KEY:
        return healthSelectors.getStringsDistribution;
      case INCIDENTS_RULES_DEFINITION_FEATURE_KEY:
        return IncidentRulesSelectors.getIncidentRulesDefinitionState;
      case INCIDENTS_RULES_THRESHOLD_FEATURE_KEY:
        return IncidentRulesSelectors.getIncidentThresholdState;
      case INCIDENTS_RULES_DELAYS_FEATURE_KEY:
        return IncidentRulesSelectors.getIncidentRulesDelaysState;
      case ENERGY_PREDICTION_FEATURE_KEY:
        return energyPredictionSelectors.getEnergyPredictionKpiHealthState;
    }
  }

  getConfigActions(config: ListConfigurationInterface, isLoading?: boolean) {
    switch (this.uniqueStateName) {
      case INCIDENTS_FEATURE_KEY:
        return incidentsDetectionActions.updateIncidentListConfiguration({ config, isLoading });
      case HEALTH_STRINGS_DISTRIBUTION_FEATURE_KEY:
        return healthAction.updateStringsDistributionListConfiguration({ config, isLoading });
      case INCIDENTS_RULES_DEFINITION_FEATURE_KEY:
      case INCIDENTS_RULES_THRESHOLD_FEATURE_KEY:
        return IncidentRulesActions.updateIncidentListConfiguration({ config, isLoading });
      case INCIDENTS_RULES_DELAYS_FEATURE_KEY:
        return IncidentRulesActions.updateIncidentDelaysListConfiguration({ config, isLoading });
      case ENERGY_PREDICTION_FEATURE_KEY:
        return EnergyPredictionActions.updatePredictionHealthKpiListConfiguration({ config, isLoading });
      default:
        console.warn(this.uniqueStateName);
        break;
    }
  }

  getSortActions(order: string) {
    switch (this.uniqueStateName) {
      case INCIDENTS_FEATURE_KEY:
        return incidentsDetectionActions.sortIncidents({ order });
      case HEALTH_STRINGS_DISTRIBUTION_FEATURE_KEY:
        return healthAction.sortStringsDistribution({ order });
      case ENERGY_PREDICTION_FEATURE_KEY:
        return EnergyPredictionActions.sortHealthPredictionList({ order });
      default:
        break;
    }
  }

  getTableConfigActions(config: ListConfigurationInterface) {
    switch (this.uniqueStateName) {
      case INCIDENTS_FEATURE_KEY:
        return incidentsDetectionActions.incidentColumnPicker({ columns: config.columns });
      case HEALTH_STRINGS_DISTRIBUTION_FEATURE_KEY:
        return healthAction.stringsDistributionColumnPicker({ columns: config.columns });
      case INCIDENTS_RULES_THRESHOLD_FEATURE_KEY:
        return IncidentRulesActions.incidentThresholdColumnPicker({ columns: config.columns });
      case INCIDENTS_RULES_DEFINITION_FEATURE_KEY:
        return IncidentRulesActions.incidentDefinitionColumnPicker({ columns: config.columns });
      case INCIDENTS_RULES_DELAYS_FEATURE_KEY:
        return IncidentRulesActions.incidentDelaysColumnPicker({ columns: config.columns });
      case ENERGY_PREDICTION_FEATURE_KEY:
        return EnergyPredictionActions.predictionHealthKpiColumnPicker({ columns: config.columns });
      default:
        break;
    }
  }

  getFilterActions(filter: Record<string, string>) {
    switch (this.uniqueStateName) {
      case INCIDENTS_FEATURE_KEY:
        return incidentsDetectionActions.filterIncidents({ filter });
      case INCIDENTS_RULES_THRESHOLD_FEATURE_KEY:
        return IncidentRulesActions.filterIncidentRules({ filter });
    }
  }
}
