import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, filter, map, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as IncidentsDetectionActions from '../actions/incidents-detection.actions';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as IncidentsDetectionSelectors from '../selectors/incidents-detection.selectors';

import { Store } from '@ngrx/store';
import { IncidentsDetectionService } from '@twaice-fe/frontend/shared/services';
import { RequestQueryBuilder } from '@twaice-fe/frontend/shared/utilities';
import { IncidentComponentOverview } from '@twaice-fe/shared/models';
import { keysToSnake } from '@twaice-fe/shared/utilities';
import { systemSelectors } from '../selectors';

@Injectable()
export class IncidentsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          IncidentsDetectionActions.fetchIncidents,
          IncidentsDetectionActions.updateIncidentListConfiguration,
          IncidentsDetectionActions.sortIncidents,
          IncidentsDetectionActions.filterIncidents,
          IncidentsDetectionActions.resetIncidentFilters,
          IncidentsDetectionActions.updateDateFilter,
        ]
      ),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getIncidentsState)),
      switchMap(([, state]) => {
        const { page, limit, order, filter: filters } = state?.config ?? { page: 1, limit: 20 };
        const formatedFilter = keysToSnake(filters || {});
        const params = new RequestQueryBuilder().build({ page, limit, order, ...formatedFilter });
        return this.incidentsDetectionService.fetchIncidents(params).pipe(
          map(({ data }) => IncidentsDetectionActions.loadIncidentsSuccess({ incidents: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Incidents]', error);
            return of(IncidentsDetectionActions.loadIncidentsFailure({ error }));
          })
        );
      })
    )
  );

  fetchAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          IncidentsDetectionActions.fetchIncidentsAnalytics,
          IncidentsDetectionActions.filterIncidentsAnalytics,
          IncidentsDetectionActions.updateDateFilter,
        ]
      ),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(IncidentsDetectionSelectors.getIncidentsState)
      ),
      switchMap(([, system, state]) => {
        let filters = {
          customerBk: system?.customerBk,
          systemBk: system?.systemBk,
        };
        const { timeRangeStart, timeRangeEnd } = state?.config?.filter ?? {};
        filters = {
          ...filters,
          ...(timeRangeStart && { timeRangeStart }),
          ...(timeRangeEnd && { timeRangeEnd }),
        };
        const formatedFilter = keysToSnake(filters);
        const params = new RequestQueryBuilder().build({ page: 1, limit: 500, ...formatedFilter });
        return this.incidentsDetectionService.fetchIncidentsAnalytics(params).pipe(
          map(({ data }) => IncidentsDetectionActions.loadIncidentsAnalyticsSuccess({ analytics: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Incidents]', error);
            return of(IncidentsDetectionActions.loadIncidentsFailure({ error }));
          })
        );
      })
    )
  );

  fetchSingleIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchSingleIncident),
      withLatestFrom(this.store.select(IncidentsDetectionSelectors.getSingleIncident)),
      switchMap(([params, state]) =>
        (state ? of({ data: state }) : this.incidentsDetectionService.fetchSingleIncident(params)).pipe(
          map(({ data }) => IncidentsDetectionActions.loadSingleIncidentSuccess({ incident: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentsDetectionActions.cancelIncidentsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Single Incidents]', error);
            return of(IncidentsDetectionActions.loadSingleIncidentFailure({ error }));
          })
        )
      )
    )
  );

  fetchStorageOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchStorageOverview),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(IncidentsDetectionSelectors.getIncidentsState)
      ),
      filter(([system]) => !!system),
      switchMap(([, system, state]) => {
        const filters = state.config?.filter;
        if (filters && filters['timeRangeStart'] && filters['timeRangeEnd']) {
          return this.incidentsDetectionService
            .fetchStorageOverview({
              customerBk: system?.customerBk as string,
              systemBk: system?.rootContainerId as string,
              timeRangeStart: filters['timeRangeStart'],
              timeRangeEnd: filters['timeRangeEnd'],
            })
            .pipe(
              map((result) => IncidentsDetectionActions.loadStorageOverviewSuccess({ overview: result.data })),
              catchError((error) => {
                console.error('[Error/Fetch Storage Overview]', error);
                return of(IncidentsDetectionActions.loadStorageOverviewFailure({ error }));
              })
            );
        } else {
          const error = '[Error/Fetch Storage Overview]';
          console.error(error);
          return of(IncidentsDetectionActions.loadStorageOverviewFailure({ error }));
        }
      })
    )
  );

  fetchNodeOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchNodeOverview),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(IncidentsDetectionSelectors.getIncidentsState)
      ),
      filter(([system]) => !!system),
      switchMap(([, system, state]) => {
        const filters = state.config?.filter;
        if (filters && filters['timeRangeStart'] && filters['timeRangeEnd']) {
          return this.incidentsDetectionService
            .fetchNodeOverview({
              customerBk: system?.customerBk as string,
              systemBk: system?.rootContainerId as string,
              timeRangeStart: filters['timeRangeStart'],
              timeRangeEnd: filters['timeRangeEnd'],
            })
            .pipe(
              map((componentOverview) =>
                IncidentsDetectionActions.loadNodeOverviewSuccess({
                  overview: this.parseOverview(componentOverview.data),
                })
              ),
              catchError((error) => {
                console.error('[Error/Fetch Node Overview]', error);
                return of(IncidentsDetectionActions.loadNodeOverviewFailure({ error }));
              })
            );
        } else {
          const error = '[Error/Fetch Node Overview]';
          console.error(error);
          return of(IncidentsDetectionActions.loadNodeOverviewFailure({ error }));
        }
      })
    )
  );

  fetchStringOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchStringOverview),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(IncidentsDetectionSelectors.getIncidentsState)
      ),
      filter(([, system]) => !!system),
      switchMap(([action, system, state]) => {
        const filters = state.config?.filter;
        if (filters && filters['timeRangeStart'] && filters['timeRangeEnd']) {
          return this.incidentsDetectionService
            .fetchNodeOverview({
              customerBk: system?.customerBk as string,
              systemBk: system?.rootContainerId as string,
              timeRangeStart: filters['timeRangeStart'],
              timeRangeEnd: filters['timeRangeEnd'],
              parentComponentBk: action.parentComponentBk,
              level: 'string',
              groupBy: action.groupBy,
            })
            .pipe(
              map((componentOverview) =>
                IncidentsDetectionActions.loadStringOverviewSuccess({
                  overview: this.parseOverview(componentOverview.data),
                  parentComponentBk: action.parentComponentBk,
                })
              ),
              catchError((error) => {
                console.error('[Error/Fetch String Overview]', error);
                return of(IncidentsDetectionActions.loadStringOverviewFailure({ error }));
              })
            );
        } else {
          const error = '[Error/Fetch String Overview]';
          console.error(error);
          return of(IncidentsDetectionActions.loadStringOverviewFailure({ error }));
        }
      })
    )
  );

  fetchMailConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.fetchMailNotificationConfig),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      filter((system) => !!system),
      switchMap(([, system]) =>
        this.incidentsDetectionService
          .fetchMailConfiguration({
            customerBk: system?.customerId as string,
            systemBk: system?.rootContainerId as string,
          })
          .pipe(
            map((mailConfigResponse) =>
              IncidentsDetectionActions.fetchMailNotificationConfigSuccess({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                mailConfig: { is_enabled: mailConfigResponse.data.is_enabled },
              })
            ),
            catchError((error) => of(IncidentsDetectionActions.fetchMailNotificationConfigFailure({ error })))
          )
      )
    )
  );

  updateMailConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentsDetectionActions.updateMailNotificationConfig),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      filter((system) => !!system),
      switchMap(([action, system]) =>
        this.incidentsDetectionService
          .updateMailConfiguration({
            customerBk: system?.customerId as string,
            systemBk: system?.rootContainerId as string,
            mailConfig: action.mailConfig,
          })
          .pipe(
            map((mailConfigResponse) =>
              IncidentsDetectionActions.updateMailNotificationConfigSuccess({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                mailConfig: { is_enabled: mailConfigResponse.data.is_enabled },
              })
            ),
            catchError((error) => of(IncidentsDetectionActions.updateMailNotificationConfigFailure({ error })))
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private incidentsDetectionService: IncidentsDetectionService,
    protected store: Store
  ) {}

  private parseOverview(overview: IncidentComponentOverview): IncidentComponentOverview {
    return this.fillDisplayBreaches(overview);
  }
  private fillDisplayBreaches(overview: IncidentComponentOverview) {
    overview.aggregates.forEach(
      (component) =>
        (component.displayBreaches = [
          ...component.upperBreaches.map((value) => `Upper threshold ${value}`),
          ...component.lowerBreaches.map((value) => `Lower threshold ${value}`),
        ])
    );
    return overview;
  }
}
