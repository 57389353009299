/* eslint-disable @typescript-eslint/no-explicit-any */
import { DestroyRef, Directive, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectors } from '@twaice-fe/frontend/shared/store';
import { OverviewSystem } from '@twaice-fe/shared/models';

// reference implementation: https://github.com/angular/angular/blob/main/packages/router/src/directives/router_link.ts
/**
 * Extends Angular’s built-in `RouterLink` to automatically prefix routes with
 * the selected system & customer from the store. When a system is available, this
 * directive prepends `/customer/:customerBk/system/:systemBk` to the provided
 * route commands or string.
 *
 * If `twRouterLink` is null or undefined, it behaves like a standard `RouterLink`.
 *
 * If `twRouterLink` is a `UrlTree`, it remains unmodified. (currently not supported)
 *
 * @example
 * ```html
 * <!-- Automatically becomes /customer/123/system/abc/dashboard if a system is selected. -->
 * <a [twRouterLink]="'dashboard'">Go to Dashboard</a>
 * ```
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[twRouterLink]',
  standalone: true,
})
export class TwRouterLinkDirective extends RouterLink implements OnInit {
  selectedSystem: OverviewSystem;
  commandsOrUrlTree: any;

  private store = inject(Store);
  private destroy$ = inject(DestroyRef);

  // some type weirdness here with Angular internal types not mapping to the exported ones
  // --> forced to 'any'
  @Input()
  set twRouterLink(commandsOrUrlTree: any) {
    // store for usage when system is updated
    this.commandsOrUrlTree = commandsOrUrlTree;

    if (commandsOrUrlTree == null) {
      // call parent setter
      this.routerLink = commandsOrUrlTree;
    } else {
      if (commandsOrUrlTree instanceof UrlTree) {
        // TODO support UrlTree objects here, niche usage
      } else {
        let parsedUrl = Array.isArray(commandsOrUrlTree) ? commandsOrUrlTree : [commandsOrUrlTree];

        if (this.selectedSystem && !parsedUrl.includes('customer')) {
          // remove potential leading slash
          if (parsedUrl[0].startsWith('/')) {
            parsedUrl[0] = parsedUrl[0].replace('/', '');
          }

          // remove '/' from url since they get sanitized if the URL is an array and not a string
          parsedUrl = parsedUrl.map((u) => u.split('/')).flat();

          // add customer and system prefixes
          parsedUrl.unshift(...['/customer', this.selectedSystem.customerBk, 'system', this.selectedSystem.systemBk]);
        }

        // call parent setter
        this.routerLink = parsedUrl;
      }
    }
  }

  ngOnInit(): void {
    this.store
      .select(selectors.systemSelectors.getSelected)
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe((selectedSystem) => {
        this.selectedSystem = selectedSystem;
        // reset link state with new selected system
        this.twRouterLink = this.commandsOrUrlTree;

        // trigger change detection to force href update in parent
        this.ngOnChanges({});
      });
  }
}
