import { createAction, props } from '@ngrx/store';
import { OverviewSystem, PaginatedResponse, TimeRangeGranularityEnum, TimeRangeInterface } from '@twaice-fe/shared/models';
import { TimeRangeEnum } from '@twaice-fe/shared/utilities';

export const fetchSystems = createAction(
  '[Systems] Fetch systems',
  props<{
    includeMetadata?: boolean;
    onlySystems?: boolean;
    includeSafetyScore?: boolean;
    includeSystemStatistics?: boolean;
  }>()
);

export const fetchSystemsHealthKpiData = createAction(
  '[Systems/API] fetch system health kpi data',
  props<{ includeMetadata?: boolean }>()
);

export const fetchSystemsEfcData = createAction(
  '[Systems/API] fetch system EFC data',
  props<{ systems: { customerBk: string; systemBk: string }[] }>()
);

export const fetchSystemsSohData = createAction(
  '[Systems/API] fetch system SOH data',
  props<{ systems: { customerBk: string; systemBk: string }[] }>()
);
export const loadSystemsSuccess = createAction(
  '[Systems/API] Load Systems Success',
  props<{ data: PaginatedResponse<OverviewSystem> }>()
);
export const loadSystemsEfcDataSuccess = createAction(
  '[Systems/API] Load Systems EFC data Success',
  props<{ data: PaginatedResponse<OverviewSystem> }>()
);
export const loadSystemsSohDataSuccess = createAction(
  '[Systems/API] Load Systems SoH data Success',
  props<{ data: PaginatedResponse<OverviewSystem> }>()
);

// set loading to false when all data is retrieved from cache
export const systemLoadingSuccess = createAction('[Systems/API] Loading Systems data finished');

export const systemEfcLoadingSuccess = createAction('[Systems/API] Loading EFC data finished');

export const systemSohLoadingSuccess = createAction('[Systems/API] Loading SOH data finished');

export const loadSystemsHealthKpiDataSuccess = createAction(
  '[Systems/API] Load Systems health kpi data Success',
  props<{ data: PaginatedResponse<OverviewSystem> }>()
);

export const selectSystem = createAction('[Systems] Select system by id', props<{ systemId: string }>());

export const loadSystemsFailure = createAction('[Systems/API] Load Systems Failure', props<{ error: any }>());

export const systemRouteRedirectSuccess = createAction('[Systems] Route Redirect Success');

export const cancelSystemRequest = createAction('[Systems/API] Cancel systems request');

export const setInitialTimeRange = createAction(
  '[Systems/API] Set inital and available time ranges',
  props<{ timeRange: TimeRangeInterface; availableTimeRanges: TimeRangeInterface[] }>()
);

export const setTimeRange = createAction('[Systems/API] Set time range', props<{ timeRange: TimeRangeInterface }>());

export const setTimeRangeGranularity = createAction(
  '[Systems/API] Set time range granularity',
  props<{ granularity: TimeRangeGranularityEnum }>()
);

export const setDefaultTimeRange = createAction(
  '[Systems/API] Set default time range',
  props<{ defaultTimeRange: TimeRangeEnum }>()
);

export const timeRangeUrlSyncSuccess = createAction('[Systems/API] Time Range URL sync success');
