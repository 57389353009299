import { TimeRangeGranularityEnum } from '../common/systems.models';

export interface BaseEnergyAnalyticResponseInterface {
  customerBk: string;
  systemBk: string;
}

export interface BaseEnergyAnalyticsParamsInterface {
  customerBk?: string;
  systemBk?: string;
}

// region Safety Recommendation models
export interface SafetyRecommendationDataInterface extends BaseEnergyAnalyticResponseInterface {
  systemState: ActionStateEnum;
  numberOfStableStrings: number;
  requiredActions: ComponentStateInterface[];
  recommendedActions: ComponentStateInterface[];
}

export interface ComponentStateInterface {
  name: string;
  componentBk: string;
  componentState: ActionStateEnum;
  kpiStates: KpiStateInterface[];
}

export interface KpiStateInterface {
  kpi: SafetyKPIEnum;
  state: ActionStateEnum;
  issueTypes: KpiIssueTypeEnum[];
}

export enum SafetyKPIEnum {
  TEMPERATURE = 'TEMPERATURE',
  RESISTANCE = 'RESISTANCE',
}

export enum ActionStateEnum {
  REQUIRED = 'ACTION_REQUIRED',
  RECOMMENDED = 'ACTION_RECOMMENDED',
  STABLE = 'STABLE',
}

export enum KpiIssueTypeEnum {
  ANOMALY = 'ANOMALY',
  TREND = 'TREND',
}

export interface SafetyRecommendationComponentDetailsParamsInterface extends BaseEnergyAnalyticsParamsInterface {
  componentBk: string;
}

export interface KpiStateDetailsInterface {
  kpi: SafetyKPIEnum;
  state: ActionStateEnum;
  issueType: KpiIssueTypeEnum;
  timestamps: Date[];
  outlierComponentValues: number[];
  stableComponentsValues: number[];
}

export interface SafetyRecommendationComponentDetailsInterface extends BaseEnergyAnalyticResponseInterface {
  componentBk: string;
  kpiStates: KpiStateDetailsInterface[];
}
//endregion

//region System Ingestion Statistics Models
export interface SystemIngestionStatistics {
  lastSeen: Date | null;
}
//endregion

//region Warranty Tracker Models
export enum WarrantyKPIEnum {
  EQUIVALENT_FULL_CYCLES = 'efc',
  STATE_OF_HEALTH = 'soh',
  AVAILABILITY = 'availability',
}

export enum SystemSoHSensorEnum {
  TWAICE_SOH_PERCENTAGE = 'TWAICE SoH Percentage',
  TWAICE_SOH_ENERGY = 'TWAICE SoH Energy',
}

export enum ReferenceSoHSensorEnum {
  REFERENCE_SOH_PERCENTAGE = 'Reference SoH Percentage',
  REFERENCE_SOH_ENERGY = 'Reference SoH Energy',
}

export enum SystemEFCSensorEnum {
  TWAICE_CUMULATIVE_EQUIVALENT_FULL_CYCLE = 'TWAICE Cumulative Equivalent Full Cycle',
  TWAICE_DAILY_EQUIVALENT_FULL_CYCLE = 'TWAICE Daily Equivalent Full Cycle',
  TWAICE_CUMULATIVE_ABSOLUTE_THROUGHPUT = 'TWAICE Cumulative Absolute Throughput',
  TWAICE_DAILY_ABSOLUTE_THROUGHPUT = 'TWAICE Daily Absolute Throughput',
  TWAICE_CUMULATIVE_DISCHARGE_THROUGHPUT = 'TWAICE Cumulative Discharge Throughput',
  TWAICE_DAILY_DISCHARGE_THROUGHPUT = 'TWAICE Daily Discharge Throughput',
}

export enum ReferenceEFCSensorEnum {
  REFERENCE_CUMULATIVE_EQUIVALENT_FULL_CYCLE = 'Reference Cumulative Equivalent Full Cycle',
  REFERENCE_DAILY_EQUIVALENT_FULL_CYCLE = 'Reference Daily Equivalent Full Cycle',
  REFERENCE_CUMULATIVE_ABSOLUTE_THROUGHPUT = 'Reference Cumulative Absolute Throughput',
  REFERENCE_DAILY_ABSOLUTE_THROUGHPUT = 'Reference Daily Absolute Throughput',
  REFERENCE_CUMULATIVE_DISCHARGE_THROUGHPUT = 'Reference Cumulative Discharge Throughput',
  REFERENCE_DAILY_DISCHARGE_THROUGHPUT = 'Reference Daily Discharge Throughput',
}

export enum SystemAvailabilitySensorEnum {
  TWAICE_AVAILABILITY_PERCENTAGE = 'TWAICE Availability Percentage',
  TWAICE_AVAILABILITY_ENERGY = 'TWAICE Availability Energy',
}

export enum ReferenceAvailabilitySensorEnum {
  REFERENCE_AVAILABILITY_PERCENTAGE = 'Reference Availability Percentage',
  REFERENCE_AVAILABILITY_ENERGY = 'Reference Availability Energy',
}

export enum WarrantyKPIUnitEnum {
  CYCLES = 'cycles',
  PERCENTAGE = '%',
  MEGA_WATT_HOURS = 'MWh',
}

export interface DataInterface {
  timestamp: string;
  value: number;
}

export interface WarrantyReferenceValueInterface {
  referenceName: ReferenceSoHSensorEnum | ReferenceEFCSensorEnum | ReferenceAvailabilitySensorEnum;
  data: DataInterface[];
  unit: WarrantyKPIUnitEnum;
}

export interface WarrantySensorValueInterface {
  sensorName: SystemSoHSensorEnum | SystemEFCSensorEnum | SystemAvailabilitySensorEnum;
  data: DataInterface[];
  unit: WarrantyKPIUnitEnum;
}

export interface WarrantyKPIParamsInterface extends BaseEnergyAnalyticsParamsInterface {
  kpi: WarrantyKPIEnum;
  startDate: string;
  endDate: string;
  granularity: TimeRangeGranularityEnum;
}

export interface WarrantyKPIDataInterface extends BaseEnergyAnalyticResponseInterface {
  kpi: WarrantyKPIEnum;
  startDate?: string;
  endDate?: string;
  granularity?: TimeRangeGranularityEnum;
  warrantySensorValues: WarrantySensorValueInterface[];
  warrantyReferenceValues: WarrantyReferenceValueInterface[];
}
//endregion
