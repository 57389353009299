import { Injectable } from '@angular/core';
import * as sentry from '@sentry/angular';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler extends sentry.SentryErrorHandler {
  constructor(
    private modal: NzModalService,
    sentryOptions?: sentry.ErrorHandlerOptions
  ) {
    super(sentryOptions);
  }

  handleError(error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    /* eslint-disable no-useless-escape */
    const tmpDeploymentUrlPattern = /^(?:https?:\/\/)?(?:[^\/@.]+\.)*tmp\.ops\.twaice\.ai(?:\/.*)?$/;
    const deploymentUrlPattern = /^(?:https?:\/\/)?(?:[^\/@.]+\.)*twaice\.cloud(?:\/.*)?$/;
    /* eslint-enable no-useless-escape */

    if (chunkFailedMessage.test(error.message)) {
      if (tmpDeploymentUrlPattern.test(error.request) || deploymentUrlPattern.test(error.request)) {
        this.promptUserForUpdate();
        return;
      }
    }

    super.handleError(error);
  }

  private promptUserForUpdate() {
    this.modal.confirm({
      nzTitle: 'A new version is available',
      nzContent: 'Do you want to update now? You can update at any time by refreshing the page.',
      nzOkText: 'Update',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        window.location.reload();
      },
    });
  }
}
