import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import { TimeRangeGranularityEnum, TimeRangeInterface } from '@twaice-fe/shared/models';
import { areDatesEqual, TimeRangeEnum } from '@twaice-fe/shared/utilities';
import { endOfMonth, startOfMonth } from 'date-fns';
import { filter, Subject, takeUntil } from 'rxjs';

const { systemSelectors } = selectors;
const { systemActions } = actions;

@Component({
  selector: 'twaice-fe-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrl: './time-selector.component.scss',
})
export class TimeSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() defaultTimeRange = TimeRangeEnum.LAST_SEVEN_DAYS;
  @Input() showGranularity = false;
  @Input() showFullYearSelector = false;

  timeRangeMapping: TimeRangeInterface[] = [];
  selectedTimeRange: TimeRangeInterface;
  selectedTimeRangeIndex: number;
  timeResolutionMapping: TimeRangeInterface[] = [
    { id: 'dailyView', value: 'date', label: 'Daily view', disabled: false },
    { id: 'monthlyView', value: 'month', label: 'Monthly view', disabled: false },
  ];
  selectedTimeResolution: TimeRangeInterface;
  selectedTimeResolutionIndex: number;
  pickerValue: [Date, Date];
  systemID: string;
  granularityOptions = Object.values(TimeRangeGranularityEnum);
  selectedGranularity: TimeRangeGranularityEnum = TimeRangeGranularityEnum.WEEKLY;

  private destroy$ = new Subject<void>();

  constructor(protected store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(systemActions.setDefaultTimeRange({ defaultTimeRange: this.defaultTimeRange }));

    this.store
      .select(systemSelectors.getSelectedTimeRange)
      .pipe(
        filter(({ timeRange }) => !!timeRange),

        takeUntil(this.destroy$)
      )
      .subscribe(({ timeRange, availableTimeRanges, timeRangeGranularity }) => {
        this.selectedTimeRange = timeRange;
        // we mutate this object so it needs to be unfrozen from the store
        this.timeRangeMapping = JSON.parse(JSON.stringify(availableTimeRanges));
        this.pickerValue = this.selectedTimeRange.value as [Date, Date];
        this.selectedTimeRangeIndex = availableTimeRanges.findIndex((range) => range.id === timeRange.id);
        this.selectedGranularity = timeRangeGranularity;
        this.evaluateAvailableOptions();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showFullYearSelector) {
      this.evaluateAvailableOptions();
    }

    if (changes.defaultTimeRange.currentValue && this.timeRangeMapping?.length) {
      // reset selected time range to default whenever the default changes
      this.onTimeRangeChange(this.defaultTimeRange);
    }
  }

  evaluateAvailableOptions(): void {
    const fullYearObj = this.timeRangeMapping.find((t) => t.id === 'threeHundredSixtyFiveDays');
    if (fullYearObj) fullYearObj.disabled = !this.showFullYearSelector;
  }

  async onDatePickerChange(result: Date[], isFromPicker = true) {
    if (result[0] == null || result[1] == null) {
      return;
    }

    this.pickerValue = [result[0], result[1]];

    if (this.selectedTimeResolution?.id === 'monthlyView' && areDatesEqual(result[0], result[1])) {
      const start = startOfMonth(result[0]);
      const end = endOfMonth(result[1]);
      this.pickerValue = [start, end];
    }

    // If the date range is selected from the picker, then set time range to custom
    if (isFromPicker) {
      this.selectedTimeRangeIndex = TimeRangeEnum.CUSTOM;
      this.timeRangeMapping[TimeRangeEnum.CUSTOM].value = this.pickerValue;
      this.selectedTimeRange = this.timeRangeMapping[TimeRangeEnum.CUSTOM];
    }

    this.store.dispatch(
      systemActions.setTimeRange({
        timeRange: {
          ...this.selectedTimeRange,
          value: [new Date(this.selectedTimeRange.value[0]), new Date(this.selectedTimeRange.value[1])],
        },
      })
    );
  }

  async onTimeResolutionChange(selectedTimeResolutionIndex: number) {
    this.selectedTimeResolution = this.timeResolutionMapping[selectedTimeResolutionIndex];
  }

  async onTimeRangeChange(selectedTimeRangeIndex: number) {
    this.selectedTimeRange = this.timeRangeMapping[selectedTimeRangeIndex];
    this.pickerValue = this.selectedTimeRange.value as [Date, Date];
    await this.onDatePickerChange(this.selectedTimeRange.value as [Date, Date], false);
  }

  async onGranularityChange(selectedGranularity: TimeRangeGranularityEnum) {
    this.store.dispatch(
      systemActions.setTimeRangeGranularity({
        granularity: selectedGranularity,
      })
    );
  }
}
