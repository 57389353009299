import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectors } from '@twaice-fe/frontend/shared/store';
import { MOBILITY_BASE_ROUTE, MOBILITY_DEPRECATED_BASE_ROUTE } from '@twaice-fe/shared/constants';
import { EnergyAnalyticsMetaData, KpisInterface, OverviewSystem, System } from '@twaice-fe/shared/models';
import { TimeRangeEnum } from '@twaice-fe/shared/utilities';
import { energyAnalyticsActions, systemActions } from 'libs/frontend/shared/store/src/actions';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { StorageMetadataDialogComponent } from './storage-metadata-dialog/storage-metadata-dialog.component';

const { systemSelectors, configsSelectors, energyAnalyticsSelectors } = selectors;

@Component({
  selector: 'twaice-fe-navigation-menu',
  templateUrl: './navigation-menu.component.html',
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  @Input() isFleet = false;
  @Input() isGlobal = false;
  @Input() isPerformanceManagerSolution = false;
  @Input() isMonitoringSolution = false;
  @Input() isIncidentDetails = false;
  @Input() isWarrantyTracker = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  TOTAL_STORAGE_COUNT = 10;
  storages$: Observable<EnergyAnalyticsMetaData[]>;
  filteredStorages$: Observable<EnergyAnalyticsMetaData[]>;
  selectedSystem: OverviewSystem;

  selectedStorage$ = new BehaviorSubject<EnergyAnalyticsMetaData>(undefined);
  selectedStorageKpis$ = new BehaviorSubject<KpisInterface>(undefined);
  systemIngestionStatisticsLastSeenTimestamp$ = new Observable<Date | null>();

  inputValue?: string;
  timeRangeEnum = TimeRangeEnum;

  private searchTerms = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.initComponentState();
    this.setupSearchSubscription();
  }

  ngOnInit(): void {
    this.store
      .select(systemSelectors.getSelected)
      .pipe(
        filter((selected) => !!selected?.id),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedSystem) => {
        if (this.selectedSystem?.id !== selectedSystem.id) {
          this.store.dispatch(energyAnalyticsActions.fetchSystemIngestionStatisticsData());
        }

        this.selectedSystem = selectedSystem;
        this.setStorages();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onRowSelected(item: System) {
    this.store.dispatch(systemActions.selectSystem({ systemId: item.id }));

    this.selectedSystem = item;
    this.setSelectedStorage();
  }

  openMetadataDialog() {
    this.dialog.open(StorageMetadataDialogComponent, {
      data: this.selectedStorage$.getValue().metadata,
    });
  }

  handleSearchInput(event) {
    const value = (event.target as HTMLInputElement).value;
    this.searchTerms.next(value);
  }

  getDefaultTimeRange() {
    if (this.isPerformanceManagerSolution) {
      return this.timeRangeEnum.LAST_SIXTY_DAYS;
    }
    if (this.isWarrantyTracker) {
      return this.timeRangeEnum.LAST_THREE_HUNDRED_SIXTY_FIVE_DAYS;
    }
    return this.timeRangeEnum.LAST_THIRTY_DAYS;
  }

  private setupSearchSubscription() {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => {
          if (term === '') {
            return this.storages$;
          }

          return this.storages$.pipe(
            map((storages) => storages.filter((storage) => storage.name.toLowerCase().includes(term.toLowerCase())))
          );
        }),
        tap((filteredStorages) => {
          this.filteredStorages$ = of(filteredStorages);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setStorages() {
    this.storages$ = this.store.select(systemSelectors.getSystemList).pipe(
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify(prev) === JSON.stringify(curr);
      })
    );

    this.storages$
      .pipe(
        take(1),
        tap(() => {
          this.setSelectedStorage();
        })
      )
      .subscribe();

    this.filteredStorages$ = this.storages$;
  }

  private setSelectedStorage() {
    if (!this.selectedSystem) return;

    this.store
      .select(systemSelectors.getSelected)
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedStorage) => {
        this.selectedStorage$.next(selectedStorage);

        if (selectedStorage?.kpis) {
          this.selectedStorageKpis$.next(selectedStorage.kpis);
        }
      });

    this.systemIngestionStatisticsLastSeenTimestamp$ = this.store.select(
      energyAnalyticsSelectors.getSystemIngestionStatisticsLastSeenTimestamp
    );
  }

  private initComponentState() {
    this.isFleet = this.router.url.includes(MOBILITY_BASE_ROUTE) || this.router.url.includes(MOBILITY_DEPRECATED_BASE_ROUTE);
  }
}
